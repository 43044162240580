/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    DateField,
    Create,
    Edit,
    SimpleForm
} from 'react-admin';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { getDuplicates, validateDuplicates, validateNoSpaces, validateOU } from '../utils/validations';
import { BQInput } from './Generic/bq-input';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { prepareFormData } from '../utils/transforms';

const organizationTransform = async (data, setDuplicates) => {
    while (data?.name?.endsWith('.')) {
        data.name = data.name.substring(0, data.name.length - 1);
    }
    const duplicates = await getDuplicates('OrganizationalUnit', data, ['name']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    data.organizationalUnit = data.name;
    return prepareFormData(data)
};

export const OrganizationList = (props) => (
    <BQModelList {...props} showDates hideTime>
        <TextField source="name" label="Clinic ID" />
        <TextField source="facilityName" />
    </BQModelList>
);

export const OrganizationEditor = (props) => {
    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'A clinic',
        duplicates: props.duplicates
    }
    return <SimpleForm {...props} autoComplete="off" warnWhenUnsavedChanges toolbar={<BQToolbar type="Clinic" />}>
        <BQSection title="Clinic Info" >
            <BQInput source="name" placeholder="BrainQ.SiteName" label="Clinic ID"
                readOnly={!!props.record.name}
                validate={[validateNoSpaces, validateOU]}
                duplicates={duplicatesCheckData}
            />
            <BQInput source="facilityName" />
        </BQSection>
    </SimpleForm>
}



const OrganizationMutation = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();

    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => organizationTransform(data, setDuplicates) }


    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <OrganizationEditor redirect={bqCreateRedirection} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <OrganizationEditor redirect={bqEditRedirection} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

export const OrganizationCreate = (props) => OrganizationMutation(true, props)

export const OrganizationEdit = (props) => OrganizationMutation(false, props)


