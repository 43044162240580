const cachedData = {}

const setCachedData = async (key, action) => {
    cachedData[key] = cachedData[key] || (await action?.()) || []
}

export const getCachedMobileDeviceUsers = async (dataProvider) => {
    await setCachedData(
        'mobileDeviceUsers',
        async () => (await dataProvider.getList('mobileDeviceUsers', {
            pagination: { page: 1, perPage: 10000 }
        }))?.data
    )
    return cachedData['mobileDeviceUsers']
}

export const getEnumFromServer = async (dataProvider, enumName) => {
    await setCachedData(
        enumName,
        async () => (await dataProvider.getOne('BQEnums', { id: enumName }))?.data?.values?.map(item => {
            return {
                name: item,
                id: item
            }
        })
    )
    return cachedData[enumName]
}

