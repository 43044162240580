import * as React from 'react';
import {
    useUpdate,
    useRefresh,
    useDelete,
    useNotify,
} from 'react-admin';
import { useBQStyles } from '../../themes';
import DraggableDialog from './ConfirmationDialog';
import { prepareFormData } from '../../utils/transforms';

export const SoftDeleteListButton = ({ id, record, resource, nameField, title, onConfirm }) => {
    const refresh = useRefresh();
    const notify = useNotify()
    const [open, setOpen] = React.useState(false);
    const resourceName = resource && resource.endsWith('s') ? resource.substring(0, resource.length - 1) : resource
    const classes = useBQStyles();
    const recordToUpdate = { ...record };

    delete recordToUpdate.treatments
    delete recordToUpdate.treatmentProtocol

    const isCognitoUser = resource === 'CognitoUsers';

    const objectName = record?.[nameField] || ''
    const actionName = title?.toLowerCase() || 'delete'

    Object.keys(recordToUpdate).forEach(field => {
        if (field.match(/_scan/i)) {
            delete recordToUpdate[field]
        }
    })

    const [softDelete, { loading }] = useUpdate(
        resourceName + ' ',
        recordToUpdate.id,
        {
            ...prepareFormData(recordToUpdate),
            ...(!isCognitoUser && { deletedAt: parseInt(new Date().getTime() / 1000) }),
        },
        recordToUpdate);

    const [softDeleteUser, { loadingUsers }] = useDelete(
        resourceName + ' ',
        recordToUpdate.id);


    const softDeleteActions = async (e) => {
        e.stopPropagation();
        setOpen(false)
        if (onConfirm) {
            return onConfirm()
        }
        if (isCognitoUser) {
            await softDeleteUser()
            setTimeout(() => {
                refresh()
                notify(`User ${objectName} was deactivated`, { type: 'success' })
            }, 500)
        } else {
            await softDelete()
            setTimeout(() => {
                refresh()
                notify(`${objectName} was deleted`, { type: 'success' })
            }, 500);
        }
    }
    return (
        <div>
            <div
                id={id}
                className={classes.removeButton}
                record={record}
                title={title || 'Delete'}
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true)
                }}
                disabled={loading}>
            </div>
            <DraggableDialog
                open={open}
                handleClose={(e) => {
                    e.stopPropagation();
                    setOpen(false)
                }
                }
                title={`${title || 'Delete'} ${objectName}`}
                content={`Are you sure you want to ${actionName} ${objectName}?`}
                onConfirm={(e) => softDeleteActions(e)}
                confirmText="Yes, I'm sure"
            />
        </div>)
}