/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    Create,
    Edit,
    SimpleForm
} from 'react-admin';
import { getDuplicates, validate, validateDuplicates, validateString } from '../utils/validations';

import { equipmentIdInApp } from '../utils/bq_enums'
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { BQInput, BQSelectInput, FunctionField, TranslationInput } from './Generic/bq-form-components';
import { limitText } from '../utils/textUtils';
import { connect } from 'react-redux';
import { BQDropDown } from './Generic/bq-input';
import { prepareFormData } from '../utils/transforms';

class EquipmentComponent extends React.Component {
    render() {
        const basePath = this.props.basePath
        const pathname = this.props.location.pathname
        const isCreate = pathname === (`${basePath}/create`)
        const isEdit = !isCreate && pathname.indexOf(`${basePath}/`) === 0
        let ComponentView = null
        if (isCreate) {
            ComponentView = EquipmentCreate
        } else if (isEdit) {
            ComponentView = EquipmentEdit
        } else {
            ComponentView = EquipmentList
        }
        return (<ComponentView {...this.props} />)
    }
}

const mapStateToProps = state => {
    return { language: state.langSelected.language };
}

export default connect(mapStateToProps, {
})(EquipmentComponent);

export const EquipmentList = (props) => {
    const { code: langCode, name: langName } = props.language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'

    return <BQModelList {...props}>
        <TextField source="name" />
        {isEnglish ? <FunctionField source="description" value={v => limitText(v)} /> : <FunctionField source="nameTranslations" label={`Name - ${langName}`} value={v => v?.find(t => t.code === langCode)?.text} />}
    </BQModelList>
}

const equipmentTransform = async (data, setDuplicates) => {
    const duplicates = await getDuplicates('Equipment', data, ['name', 'idInApp']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }
    return prepareFormData(data)
}

const EquipmentEditor = props => {
    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'Equipment',
        duplicates: props.duplicates
    }

    const { code: langCode, name: langName } = props.language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'

    return <SimpleForm {...props} autoComplete="off" warnWhenUnsavedChanges toolbar={<BQToolbar type="Equipment" />}>
        <BQSection title="Equipment Info" language={props.language}>
            <TranslationInput {...props} source="name" validate={validateString} duplicates={duplicatesCheckData} />

            <BQDropDown
                source="idInApp"
                choices={equipmentIdInApp}
                validate={validate}
                duplicates={duplicatesCheckData}
            />

            {/* <BQSelectInput
                source="idInApp"
                choices={equipmentIdInApp}
                validate={validate}
                duplicates={duplicatesCheckData}
            /> */}
            <TranslationInput {...props} multiline source="description" />
        </BQSection>
    </SimpleForm>
}

const EquipmentMutation = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();
    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => equipmentTransform(data, setDuplicates) }
    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <EquipmentEditor {...props} redirect={bqCreateRedirection} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <EquipmentEditor {...props} redirect={bqEditRedirection} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const EquipmentCreate = (props) => EquipmentMutation(true, props)

const EquipmentEdit = (props) => EquipmentMutation(false, props)
