/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    Create,
    Edit,
    SimpleForm
} from 'react-admin';
import { getDuplicates, validateDuplicates, validateString } from '../utils/validations';

import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { BQInput } from './Generic/bq-input';
import { prepareFormData } from '../utils/transforms';

export const LanguageList = (props) => {
    return (
        <BQModelList {...props} nameField="name">
            <TextField label="Language" source="name" />
            <TextField label="Code" source="code" />
        </BQModelList>
    )
}

const languageTransform = async (data, setDuplicates) => {
    data.name = data.name?.trim()
    data.code = data.code?.trim()
    const duplicates = await getDuplicates('Language', data, ['name', 'code']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }
    return prepareFormData(data)
}

const LanguageEditor = props => {
    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'Language',
        duplicates: props.duplicates
    }
    return (
        <SimpleForm {...props} autoComplete="off" warnWhenUnsavedChanges toolbar={<BQToolbar type="Language" nameField="name" />}>
            <BQSection title="Language Info">
                <BQInput source="name" label="Language name" validate={validateString} duplicates={duplicatesCheckData} />
                <BQInput source="code" label="Language code" validate={validateString} duplicates={duplicatesCheckData} />
            </BQSection>
        </SimpleForm>
    )
}

const LanguageMutation = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();

    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => languageTransform(data, setDuplicates) }

    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <LanguageEditor redirect={bqCreateRedirection} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <LanguageEditor redirect={bqEditRedirection} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

export const LanguageCreate = (props) => LanguageMutation(true, props)

export const LanguageEdit = (props) => LanguageMutation(false, props)
