/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    required,
    Create,
    Edit,
    SimpleForm,
    ReferenceInput,
    AutocompleteArrayInput,
    useMutation,
    BooleanField,
    useNotify,
    FormDataConsumer,
    useRedirect
} from 'react-admin';
import { connect, useDispatch } from 'react-redux';
import { bqAuthData } from '../utils/bq-auth';
import { choicesRole } from '../utils/bq_enums';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { getDateTime } from '../utils/textUtils';
import { getDuplicates, validate, validateDuplicates, validateEmail, validatePhoneNumber, validateUsename } from '../utils/validations';
import { BQLabelWithInput, FunctionField } from './Generic/bq-form-components';
import { BQDropDown, BQInput } from './Generic/bq-input';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { prepareFormData } from '../utils/transforms';
import { Button, CircularProgress } from '@material-ui/core';
import { tempPasswordGenerated } from '../redux/dispatchers/tempPasswordActions';

const validateOU = [required()];

class CognitoUserComponent extends React.Component {
    render() {
        const basePath = this.props.basePath
        const pathname = this.props.location.pathname
        const isCreate = pathname === (`${basePath}/create`)
        const isEdit = !isCreate && pathname.indexOf(`${basePath}/`) === 0
        let ComponentView = null
        if (isCreate) {
            ComponentView = CognitoUserCreate
        } else if (isEdit) {
            ComponentView = CognitoUserEdit
        } else {
            ComponentView = CognitoUserList
        }
        return (<ComponentView {...this.props} />)
    }
}

const validateRoles = (roles) => {
    if (!roles || roles.length === 0) {
        return 'Required'
    }
    if (!checkAllowedRoleCombination(roles, [['Administrator', 'Operator']])) {
        return 'You cannot create a user with the combination of these roles'
    }
    return null
}

const mapStateToProps = state => {
    return { tempPassword: state.tempPasswordObject.tempPassword };
}

export default connect(mapStateToProps, {
})(CognitoUserComponent);

const checkAllowedRoleCombination = (roles, allowedCombinations) => {
    if (!roles || roles.length === 1) {
        return true
    }

    return roles.some(item => item === 'Super Administrator') ||
        allowedCombinations.some(combination =>
            roles.length === combination.length &&
            !roles.some(role => !combination.some(comb => role === comb)))
}

const cognitoUserTransform = async (data, setDuplicates) => {
    const duplicates = await getDuplicates('CognitoUser', data, ['email']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    delete data.organizationalUnitName
    try {
        data.role = JSON.stringify(data.role)
    }
    catch {

    }
    const toReturn = prepareFormData(data)
    delete toReturn.deletedAt
    delete toReturn.tempPassword
    return toReturn
};

const CognitoUserList = (props) => {
    return <BQModelList {...props}
        disableDelete={(record) => !(record?.isActive)}
        deleteIconLabel="Deactivate user"
        perPage="25"
        nameField="email">
        <TextField source="email" />
        <FunctionField
            label="Role"
            source="role"
            value={(val) => {
                let rolesArray = val
                try {
                    rolesArray = JSON.parse(val)
                }
                catch {
                    return ''
                }
                const roleString = rolesArray?.map(role => choicesRole.find(r => r.id === role)?.name).reduce((acc, role) => `${acc}, ${role}`)
                return roleString
            }} />
        <TextField source="organizationalUnitName" label="Clinic" />
        <FunctionField label="Created" source="createDate" value={(val) => getDateTime(val)} />
        <FunctionField label="Last updated" source="lastModifiedDate" value={(val) => getDateTime(val)} />
        <BooleanField label="Activation status" source="isActive" />
    </BQModelList>
}

const ResetPasswordButton = (props) => {
    const { record } = props
    const [resetPassword, { loading, loaded, error, data }] = useMutation()
    const [resetButtonState, setResetButtonState] = React.useState(true)
    const notify = useNotify()
    const dispatch = useDispatch()


    React.useEffect(() => {
        if (loaded) {
            if (data.tempPassword) {
                dispatch(tempPasswordGenerated(data.email, data.tempPassword))
                setResetButtonState(false)
                notify('An email was sent to the user, please ask the user to follow the instructions in the email',
                    { autoHideDuration: 6000, type: 'success' })
            } else {
                notify('Error sending email to the user, please try again later',
                    { autoHideDuration: 6000, type: 'success' })
            }
        }
    }, [loaded])

    return resetButtonState ?
        <Button label="Activate / Reset password"
            className="MuiButton-contained"
            style={{ minWidth: '320px', boxShadow: 'none' }}
            onClick={async () => {
                const recordToSend = { ...prepareFormData(record) }
                delete recordToSend.organizationalUnitName
                delete recordToSend.tempPassword
                delete recordToSend.deletedAt
                recordToSend.role = JSON.stringify(recordToSend.role)
                resetPassword({
                    type: 'update',
                    resource: 'CognitoUsers',
                    payload: { id: recordToSend.id, data: { ...recordToSend, customAction: 'resendPassword' } }
                })
            }} disabled={loading}>{!!loading && <CircularProgress style={{ width: '16px', height: '16px', marginTop: '2px', marginRight: '16px' }} />}<span>Activate / Reset password</span></Button>
        :
        <></>
};

const CognitoUserEditor = (props) => {
    const dispatch = useDispatch()
    const redirect = useRedirect()
    if (!Array.isArray(props?.record?.role)) {
        try {
            props.record.role = JSON.parse(props?.record?.role)
        }
        catch {
        }
    }

    const { isSuperAdmin, isMarketTargetAudience } = bqAuthData
    const validRoles = (isSuperAdmin ? choicesRole : choicesRole.filter(item => item.id !== 'Super Administrator')).filter(role => !isMarketTargetAudience || role.name !== 'Randomizer')
    props.record.role = props.record.role?.map(r =>
        choicesRole.find(cRole => {
            return cRole.id.toLowerCase() === r.toLowerCase()
        }).id
    );

    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'A user',
        duplicates: props.duplicates
    }

    return <SimpleForm {...props} autoComplete="off" warnWhenUnsavedChanges toolbar={<BQToolbar type="User" nameField="email" onSuccess={response => {
        const { data } = response
        if (data?.tempPassword) {
            dispatch(tempPasswordGenerated(data.email, data.tempPassword))
        }
        redirect(props.basePath)
    }} />}>
        <BQSection title="User Info">
            <BQInput source="email" validate={validateEmail} duplicates={duplicatesCheckData} readOnly={props.record.email} />
            <BQInput label="Username" source="bqUsername" validate={validateUsename} placeholder="First name.Last name (or initials)" />
            <FormDataConsumer>
                {({ formData }) => {
                    const displayPhoneNumber = formData?.role?.some(role => role.match(/Super administrator/i))
                    return displayPhoneNumber && <BQInput type="phoneNumber" label="Phone number" source="phoneNumber" validate={validatePhoneNumber} placeholder="Phone number with country code" />
                }}
            </FormDataConsumer>

            <BQLabelWithInput
                idClassName="roleList">
                Role(s) *
                <AutocompleteArrayInput source="role" choices={validRoles} variant="outlined" validate={validateRoles} />
            </BQLabelWithInput>

            <ReferenceInput
                perPage={1000}
                label="Clinic"
                source="organizationalUnitId"
                reference="organizationalUnits" validate={validateOU}>
                <BQDropDown source="organizationalUnitId" validate={validate} />
            </ReferenceInput>
        </BQSection>
        {props?.record?.id &&
            <>
                <br />
                <br />
                <ResetPasswordButton {...props} />
            </>
        }
    </SimpleForm>
};

const CognitoUserMutate = (isCreate, props) => {
    const { tempPassword } = props
    const [duplicates, setDuplicates] = React.useState();

    const editorProps = { ...props, ...editorGlobalProps(), transform: (data) => cognitoUserTransform(data, setDuplicates) }
    if (isCreate) {
        editorProps.record = { isActive: true }
    }

    return (
        <div>
            {isCreate ?
                (<Create {...editorProps}>
                    <CognitoUserEditor tempPassword={tempPassword} redirect={bqCreateRedirection} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...editorProps} >
                    <CognitoUserEditor tempPassword={tempPassword} redirect={bqEditRedirection} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const CognitoUserCreate = (props) => CognitoUserMutate(true, props)

const CognitoUserEdit = (props) => CognitoUserMutate(false, props)


