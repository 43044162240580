/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React from 'react';
import {
    Edit,
    TextField,
    Create,
    SimpleForm,
    DataProviderContext,
    LinearProgress,
} from 'react-admin';
import { getDuplicates, validate, validateDuplicates, validateString } from '../utils/validations';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQCheckbox, TranslationInput } from './Generic/bq-form-components';
import { QuestionnaireItemTypes, choicesRole } from '../utils/bq_enums';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { BQDropDown, BQInput } from './Generic/bq-input';

import { connect } from 'react-redux';
import BQEditableDataGrid from './Generic/BQEditableDataGrid';
import { prepareFormData } from '../utils/transforms';
import { getEnumFromServer } from '../utils/globals';

class QuestionnaireComponent extends React.Component {
    render() {
        const basePath = this.props.basePath
        const pathname = this.props.location.pathname
        const isCreate = pathname === (`${basePath}/create`)
        const isEdit = !isCreate && pathname.indexOf(`${basePath}/`) === 0
        let ComponentView = null
        if (isCreate) {
            ComponentView = QuestionnaireCreate
        } else if (isEdit) {
            ComponentView = QuestionnaireEdit
        } else {
            ComponentView = QuestionnaireList
        }
        return (<ComponentView {...this.props} />)
    }
}

const mapStateToProps = state => {
    return { language: state.langSelected.language };
}

export default connect(mapStateToProps, {
})(QuestionnaireComponent);

const QuestionnaireList = (props) => (
    <BQModelList {...props}>
        <TextField source="name" />
        <TextField source="description" />
    </BQModelList>
)

const QuestionnaireEditor = props => {
    props.record.items = props.record.items?.filter(item => Object.keys(item).length)
    const { language: { code: langCode, name: langName } } = props

    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'Questionnaire',
        duplicates: props.duplicates
    }

    const [autofillFields, setAutofillFields] = React.useState()
    const dataProvider = React.useContext(DataProviderContext);

    React.useEffect(async () => {
        const enumValues = await getEnumFromServer(dataProvider, 'AutofillFields')
        console.log(enumValues)
        setAutofillFields(enumValues)
    }, [])


    return (
        <SimpleForm {...props} autoComplete="off" warnWhenUnsavedChanges toolbar={<BQToolbar type="Questionnaire" />}>
            <BQSection title="Questionnaire Info" language={props.language} style={{ maxWidth: '100%' }}>
                <BQInput source="name" validate={validateString} duplicates={duplicatesCheckData} />
                <BQDropDown
                    label="For Role"
                    source="role"
                    choices={choicesRole.filter(item => item.id === 'Caregiver')}
                    validate={validate}
                    unsorted
                />
                <BQInput source="description" />
            </BQSection>
            <BQSection title="Questions" language={props.language} style={{ maxWidth: '100%' }}>
                <BQEditableDataGrid source="items">
                    <BQInput source="title" validate={validate} />
                    <TranslationInput source="question" multiline width={480} validate={validate} />
                    <BQDropDown source="type" label="Type of question" choices={QuestionnaireItemTypes} unsorted validate={validate} width={160} />

                    {!!autofillFields
                        &&
                        <BQDropDown source="autofillField" label="AutoFill" choices={autofillFields} unsorted validate={validate} width={192} />
                        ||
                        <LinearProgress />
                    }

                    <BQCheckbox label={`ReadOnly-AutoFill`} source="readOnlyAutofill" width={92} />

                    <TranslationInput source="options" placeholder="Opt1|Opt2|Op3" />
                    {/* <TranslationInput source="comment" multiline width={256} />
                    <BQInput source="commentIndex" width={64} type="number" />
                    <BQCheckbox label={`Enable "Other"`} source="enableOther" width={64} /> */}
                    <BQCheckbox label="Mandatory" source="required" width={64} />
                </BQEditableDataGrid>
            </BQSection>
        </SimpleForm>
    )
}

const mutationTransform = async (data, setDuplicates) => {
    const duplicates = await getDuplicates('Questionnaire', data, ['name']);
    setDuplicates(duplicates)
    if (duplicates?.length) {
        return false
    }
    data.items?.forEach((item, index) => item.index = index + 1)
    return prepareFormData(data)
};

const QuestionnaireMutate = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();

    const editorProps = { ...props, ...editorGlobalProps(), transform: (data) => mutationTransform(data, setDuplicates) }

    return (
        <div>
            {isCreate ?
                (<Create {...editorProps} record={{ items: [{}] }}>
                    <QuestionnaireEditor {...props} redirect={bqCreateRedirection} duplicates={duplicates} />
                </Create>) :
                (<Edit {...editorProps} >
                    <QuestionnaireEditor {...props} redirect={bqEditRedirection} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const QuestionnaireCreate = props => QuestionnaireMutate(true, props);

const QuestionnaireEdit = props => QuestionnaireMutate(false, props); 
