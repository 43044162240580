/* eslint-disable react/prop-types */
import SvgIcon from '@material-ui/icons/Clear';
import { Button, Datagrid, Filter, List, Pagination, SaveButton, TextInput } from 'ra-ui-materialui';
import * as React from 'react';
import { CreateButton, ExportButton, ReferenceInput, TopToolbar } from 'react-admin';
import { BQIcons } from '../../symbols';
import { useBQStyles } from '../../themes'
import { bqAuthData } from '../../utils/bq-auth';
import { getDateTime, replacePlaceholders } from '../../utils/textUtils';
import { ActionField, BQSelectInput, ConditionField, FunctionField } from './bq-form-components';
import DraggableDialog from './ConfirmationDialog';
import { SoftDeleteListButton } from './SoftDeleteListButton';
import { cloneChild } from './UITools';
import { CircularProgress, LinearProgress } from '@material-ui/core';


const setSubjectOrPatient = (value) => value?.replace(/\[subjectOrPatient\]/gi, bqAuthData.subjectOrPatient)

export const BQProgress = ({ type }) => <div style={{ textAlign: 'center' }}>{type === 'linear' ? <CircularProgress /> : <LinearProgress />}</div>

export const BQToolbar = (props) => {
    const { type, label, labelSubmit, nameField, bqForm, record, content, basePath, confirmation, hideButtons, exporter } = props

    const classes = useBQStyles()
    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState();
    const [dialogContent, setDialogContent] = React.useState({});

    const { dialogTitle, dialogText, dialogQuestionIndex } = dialogContent

    const goBack = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const backUrl = `${origin}/#${basePath}`;
        window.location = backUrl;
    }

    const confirm = (confirmationIndex) => {
        const askForConfirmationResult = confirmation?.[confirmationIndex]?.condition(bqForm, record)
        if (askForConfirmationResult) {
            const { title, text } = confirmation[confirmationIndex]

            setDialogContent({
                dialogTitle: replacePlaceholders(
                    setSubjectOrPatient(title),
                    bqForm.formData,
                    askForConfirmationResult),
                dialogText: replacePlaceholders(
                    setSubjectOrPatient(text),
                    bqForm.formData,
                    askForConfirmationResult),
                dialogQuestionIndex: confirmationIndex
            })
            setConfirmationDialogOpen(true)
        } else {
            const hasMoreToConfirm = !!(confirmation?.[confirmationIndex + 1])
            if (hasMoreToConfirm) {
                confirm(confirmationIndex + 1)
            } else {
                setConfirmationDialogOpen(false)
                props.handleSubmitWithRedirect()
            }
        }
    }

    return (
        <>
            <div className={classes.toolbarContainer}>
                <table className={classes.toolbarFormButtonsContainer}>
                    <tr>
                        <td className={classes.toolbarMinimumCell}>
                            <div className={classes.backButton} onClick={(e) => goBack(e)}>
                                <SvgIcon id="back_button" component={BQIcons.iconBack} viewBox="0 0 24 24" />
                            </div>
                        </td>
                        <td className={classes.toolbarContentCell}>
                            <table>
                                <tr>
                                    <td>{label || record?.[`${nameField || 'name'}`] || `New ${type}`}</td>
                                    <td>{content}</td>
                                </tr>
                            </table>
                        </td>
                        <td className={classes.toolbarMinimumCell}>
                            <table className={classes.toolbarFormButtonsContainer}>
                                <tr>
                                    {!hideButtons && <td>
                                        <Button {...props} label="Cancel" onClick={(e) => goBack(e)} />
                                    </td>}
                                    {!hideButtons && <td>
                                        <SaveButton {...props}
                                            label={labelSubmit || 'Save'}
                                            submitOnEnter={false}
                                            handleSubmitWithRedirect={() => confirm(0)}
                                        />
                                    </td>}
                                    {exporter && <td><Button label="Export List" onClick={() => exporter()} style={{ minWidth: '160px' }} /></td>}
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>

            {confirmation?.[dialogQuestionIndex] && <DraggableDialog
                open={confirmationDialogOpen}
                dialogIndex={dialogQuestionIndex}
                handleClose={(e) => {
                    e.stopPropagation();
                    setConfirmationDialogOpen(false)
                }}
                title={dialogTitle}
                content={dialogText}
                onConfirm={(e) => {
                    e.stopPropagation()
                    confirm(dialogQuestionIndex + 1)
                }}
                confirmText="Yes, I'm sure"
            />}
        </>
    )
}

export const BQFilter = (props) => {
    const { resource, nameField, ...rest } = props
    const queryNameField = nameField || 'name'
    const resourceName = resource[0].toUpperCase() + resource.substring(1);
    const listQuery = `${resourceName[0].toLowerCase() + resourceName.substring(1)}By${queryNameField[0].toUpperCase() + queryNameField.substring(1)}.filter`

    const classes = useBQStyles();

    return (<Filter {...rest}>
        <TextInput id="filter_input" placeholder=' ' autoComplete={null} variant="outlined" source={listQuery} label={''} alwaysOn resettable />
    </Filter>)
}

export const BQPagination = props => (
    <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

export const BQListToolbar = (props) => {
    const { exporter } = props
    let objectName = props.defaultTitle;
    objectName = objectName.endsWith('ies') ? `${objectName.substring(0, objectName.length - 3)}y` : objectName
    objectName = objectName.endsWith('s') ? objectName.substring(0, objectName.length - 1) : objectName
    let newItemButtonLabel = `Add ${objectName}`
    switch (objectName) {
        case 'Text Message':
            newItemButtonLabel = 'New Text Message'
            break;
    }
    return (<TopToolbar>
        {((bqAuthData.isAdmin && objectName !== 'Device') || bqAuthData.isSuperAdmin) && <CreateButton id="add_item" label={newItemButtonLabel} style={{ width: 'auto', }} />}
        {bqAuthData.isSuperAdmin && <ExportButton id="export_list" label="Export list" style={{ width: 'auto', }} {...(exporter && { exporter } || {})} />}
    </TopToolbar>)
}

export const BQModelList = (props) => {
    const classes = useBQStyles();
    const { nameField, basePath, disableDelete, deleteIconLabel, showDates, disableEdit, expand, exporter, dataGridRef, hideTime, customButton } = props

    const label = !disableDelete || deleteIconLabel ? (deleteIconLabel || 'Delete') : ''
    return (
        <List id={`${props.options.label}_list`} {...props} bulkActionButtons={false} perPage="50" pagination={<BQPagination />} filters={<BQFilter {...props} />} actions={<BQListToolbar exporter={exporter} />}>
            <Datagrid
                rowClick={(e) => {
                    window.location = `${window.location.href.substring(0, window.location.href.indexOf('#') + 1)}${basePath}/${e}`
                }}
                ref={dataGridRef}
                expand={expand}
            >
                {
                    React.Children.map(props.children, child => {
                        if (React.isValidElement(child)) {
                            return React.cloneElement(child, {
                                sortable: false,
                            });
                        }
                        return child;
                    })
                }
                {showDates && <FunctionField id="createdAt" label="Created" source="createdAt" value={v => getDateTime(v, hideTime)} />}
                {showDates && <FunctionField id="updatedAt" label="Last updated" source="updatedAt" value={v => getDateTime(v, hideTime)} />}
                {customButton && <div className={customButton.className} title={customButton.title} ></div>}
                <ConditionField hideIf={disableEdit} style={{ maxWith: '64px' }}>
                    <div className={classes.editButton} title={'Edit'} ></div>
                </ConditionField>
                <ConditionField label={label} hideIf={disableDelete} style={{ maxWith: '64px' }}>
                    <SoftDeleteListButton id="delete_button" nameField={nameField || 'name'} title={deleteIconLabel} />
                </ConditionField>
            </Datagrid>
        </List>
    )
}

export const BQSection = (props) => {
    const { title, readOnly, style, language, headerPadding } = props
    const { code: langCode, name: langName } = language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'
    const classes = useBQStyles();

    const propsToCopy = { ...props }
    delete propsToCopy.children

    const children = React.Children.map(props.children, child => {
        return cloneChild(child, propsToCopy, readOnly)
    })

    return (
        <div className={classes.BQSectionContainer} style={style}>
            <div className={classes.BQSectionHeader} id={`BQSection_${title.trim().replace(/\s/g, '_')}`} style={{ ...(headerPadding !== undefined ? { paddingTop: headerPadding } : {}) }}>{`${(title || 'Title').toUpperCase()} ${isEnglish ? '' : ' - ' + langName}`}</div>
            <table className={classes.BQSectionContent}>{children}</table>
        </div >)
}



export const BQSideBySide = (props) => {
    const classes = useBQStyles();
    const columnGap = props.columnGap || 128;
    const children = Array.isArray(props.children) ? props.children : [props.children]

    const { style } = props

    const childrenComponents = children.map((child, index) => {
        return (<td className={classes.sideBySideTableColumn} style={{
            paddingLeft: `${(index !== 0 ? columnGap / 2 : 0)}px`,
            paddingRight: `${(index !== children.length - 1 || children.length === 1 ? columnGap / 2 : 0)}px`,
            width: `${100 / (children.length || 1)}%`
        }}>
            <p>{React.isValidElement(child) ? React.cloneElement(child) : child}</p>
        </td>)
    })

    return <table className={classes.sideBySideTable} style={style}>
        <tr>
            {childrenComponents}
        </tr>
    </table>
}

export const BQSecondarySection = (props) => (<tr {...props}><td colSpan="2">{props.children}</td></tr>)