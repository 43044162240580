import { Table, TableBody, TableHead } from '@material-ui/core';
import React, { useState } from 'react';
import { useInput } from 'react-admin';
import { useBQStyles } from '../../themes';
import { Button } from 'ra-ui-materialui';
import { SoftDeleteListButton } from './SoftDeleteListButton';
import { getLabel } from '../../utils/textUtils';

function BQEditableDataGrid(props) {
    const [editedIndex, setEditedIndex] = useState();
    const classes = useBQStyles()

    const inputData = useInput({ ...props })

    const handleAddItem = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditedIndex(inputData.input.value.length);
        inputData.input.onChange([...inputData.input.value, {}])
    };

    const handleRemoveItem = (index) => {
        const items = Array.from(inputData.input.value)
        items.splice(index, 1);
        inputData.input.onChange(items)
    };

    const handleEdit = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        setEditedIndex(index);
    }

    const handleDoneEditing = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        setEditedIndex(undefined);
    }

    const handleMoveUp = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        const items = Array.from(inputData.input.value)
        if (index > 0) {
            const spliced = items.splice(index, 1);
            items.splice(index - 1, 0, spliced[0]);
            inputData.input.onChange(items)
            if (editedIndex == index) {
                setEditedIndex(index - 1);
            } else if (editedIndex == index - 1) {
                setEditedIndex(index);
            }
        }
    }

    const handleMoveDown = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        const items = Array.from(inputData.input.value)
        if (index < items.length - 1) {
            const spliced = items.splice(index, 1);
            items.splice(index + 1, 0, spliced[0]);
            inputData.input.onChange(items)
            if (editedIndex == index) {
                setEditedIndex(index + 1);
            } else if (editedIndex == index + 1) {
                setEditedIndex(index);
            }
        }
    }

    return (
        <>
            <div style={{ margin: "16px -62px" }}>
                <div className={classes.addButton} style={{ transform: 'translateX(42px) translateY(10px)' }}></div>
                <Button
                    label="Add"
                    onClick={handleAddItem}
                    className='RaListToolbar-floating'
                />
            </div>

            <Table style={{ width: '100%' }}>
                <TableHead className={classes.BQTableHeader}>
                    <th style={{ minWidth: '32px', paddingLeft: '8px', textAlign: 'left', verticalAlign: 'top' }}>#</th>
                    {createHeader(props)}
                </TableHead>

                <TableBody>
                    {
                        inputData.input.value?.map((item, index) =>
                            <tr className={classes.bqTableRow}>
                                <td className={classes.editableListNumberingColumn}><div className={classes.editableListNumbering}>{index + 1}</div></td>
                                {createChildren({ ...props, index, editedIndex, classes })}
                                <td>
                                    {index !== editedIndex
                                        ?
                                        <div id={`edit_${index}`} className={classes.editButton} title={'Edit'} onClick={(e) => handleEdit(e, index)} ></div>
                                        :
                                        <div id={`done_${index}`} className={classes.saveButton} title={'Save'} onClick={(e) => handleDoneEditing(e, index)} ></div>
                                    }
                                </td>
                                <td><div id={`move_up_${index}`} className={`${classes.upButton} ${classes.thinButton}`} onClick={(e) => handleMoveUp(e, index)} title={'Move up'}></div></td>
                                <td><div id={`move_down_${index}`} className={`${classes.downButton} ${classes.thinButton}`} onClick={(e) => handleMoveDown(e, index)} title={'Move down'}></div></td>
                                <td><SoftDeleteListButton id={`delete_button_${index}`} title="Delete Item" onConfirm={(e) => handleRemoveItem(index)} /></td>
                            </tr>
                        )
                    }
                </TableBody>
            </Table>
        </>
    );
}

const createHeader = ({ children, language }) => children.map(child => {
    return <th style={{
        width: `${Math.ceil(100 / children.length)}%`,
        textAlign: 'left',
        lineHeight: '1.6em',
        ...(child.props.width ? { minWidth: child.props.width } : {}),
        ...(child.props.width ? { max: child.props.width } : {})

    }}>{`${getLabel(child, language)}${!!child.props.validate ? ' *' : ''}`}</th>
})

const createChildren = ({ index, source, children, editedIndex, classes }) => {
    return React.Children.map(children, child => {
        const childComponent = React.isValidElement(child) ? React.cloneElement(child, {
            source: `${source}[${index}].${child.props.source}`,
            noLabel: true,
            readOnly: index !== editedIndex
        }) : child;
        return <td className={classes.editableListDataColumn}><table style={{ width: '100%' }}>{childComponent}</table></td>
    })
}

export default BQEditableDataGrid;