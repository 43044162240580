import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BQAuthenticator } from './components/auth/BQAuthenticator';
import { endVideoMeeting } from './utils/bq-zoom';
import { resetSessionTimeout } from './utils/constants';
import BQDataProvider from './BQDataProvider';


const baseError = console.error;
console.error = function filterWarnings(msg) {
    const supressedWarnings = ['Warning:', 'Material-UI'];
    try {
        if (!supressedWarnings.some(entry => msg && msg.includes(entry))) {
            baseError.apply(console, arguments);
        } else {
            console.warn(msg);
        }
    }
    catch {
        baseError.apply(console, arguments);
    }
};

ReactDOM.render(
    <React.StrictMode>
        <BQAuthenticator>
            <App />
        </BQAuthenticator>
    </React.StrictMode>,
    document.getElementById('root')
);

window.onbeforeunload = function (e) {
    endVideoMeeting()
    sessionStorage.setItem('reload', new Date().getTime())
    BQDataProvider.saveState()
};

window.onmousemove = function (e) {
    resetSessionTimeout()
}

window.onkeydown = function (e) {
    resetSessionTimeout()
}

resetSessionTimeout()