export const bqAuthData = {}

export const setBQAuthData = (autheticatedUser) => {

    const claims = JSON.parse(autheticatedUser.signInUserSession.accessToken.payload['cognito:groups'])

    bqAuthData.isMarketTargetAudience = claims?.TARGET_AUDIENCE?.indexOf('MARKET') > -1
    bqAuthData.isAlzheimers = claims?.TARGET_AUDIENCE?.indexOf('ALZ_') > -1
    bqAuthData.email = autheticatedUser.attributes.email
    bqAuthData.name = autheticatedUser.attributes.name || autheticatedUser.attributes.email
    bqAuthData.role = autheticatedUser.attributes.preferred_username
    bqAuthData.ou = autheticatedUser.attributes.given_name
    try {
        bqAuthData.role = JSON.parse(bqAuthData.role)
    } catch {
        bqAuthData.role = bqAuthData.role
    }

    bqAuthData.isAdmin = !!bqAuthData.role.some(role => role.match(/administrator/i));
    bqAuthData.isSuperAdmin = !!bqAuthData.role.some(role => role.match(/super administrator/i));
    if (!bqAuthData.isMarketTargetAudience) {
        bqAuthData.isRandomizator = !!bqAuthData.role.some(role => role.match(/randomizator/i));
    }
    bqAuthData.isAuditor = !!bqAuthData.role.some(role => role.match(/auditor/i));
    bqAuthData.isOperator = !!(bqAuthData.role.some(role => role.match(/operator/i)));
    bqAuthData.isViewer = !!(bqAuthData.role.some(role => role.match(/viewer/i)));
    bqAuthData.userAllowed = bqAuthData.isAdmin || bqAuthData.isAuditor || bqAuthData.isViewer || bqAuthData.isOperator || (!bqAuthData.isMarketTargetAudience && bqAuthData.isRandomizator)
    bqAuthData.subjectOrPatient = bqAuthData.isMarketTargetAudience ? 'Patient' : 'Subject'
}

export const clearBQAuthData = () => {
    const keys = [...Object.keys(bqAuthData)];
    keys.forEach(key => delete bqAuthData[key]);
}