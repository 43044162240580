import { Auth } from 'aws-amplify';
const awsmobile = require('./aws-exports');

const queries = {
    ...require('./graphql/queries'),
    ...require('./extensions/queries'),
}

const mutations = {
    ...require('./graphql/mutations'),
}

const MUTATIONS = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete'
}

const paginationData = JSON.parse(sessionStorage.getItem('paginationData') || '{}')

const url = awsmobile.default.aws_appsync_graphqlEndpoint;

const fetchWithToken = async (method, query, variables) => {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
        },
        body: JSON.stringify({ query, variables }),
    });
    const jsonResponse = await response.json();
    const { data } = jsonResponse
    if (!data) {
        if (jsonResponse?.message?.match(/Token has expired/)) {
            location = '/'
        } else {
            console.error(method, jsonResponse)
        }
    }
    return data;
};

const cleanVariablesInput = (data) => {
    delete data?.createdAt
    delete data?.updatedAt
}

const getResourceListName = (resource) => {
    return `${resource.charAt(0).toUpperCase()}${resource.slice(1)}`;
};

const getResourceName = (resource) =>
    getResourceListName(resource)?.slice(0, resource.length - 1)

const getListQuery = (resource) => {
    const queryName = `list${getResourceListName(resource)}`
    const query = queries[queryName]
    if (!query) {
        throw new Error(`Query not found: ${queryName}`)
    }
    return {
        queryName,
        query
    }
};

const getOneQuery = (resource) => {
    const queryName = `get${getResourceName(resource)}`
    const query = queries[queryName]
    if (!query) {
        throw new Error(`Query not found: ${queryName}`)
    }
    return {
        queryName,
        query
    }
};

const getMutation = (resource, mutationType) => {
    const mutationName = `${mutationType}${getResourceName(resource)}`
    const mutation = mutations[mutationName]
    if (!mutation) {
        throw new Error(`Mutation not found: ${mutationName}`)
    }
    return {
        mutationName,
        mutation
    }
};

const getOne = async (resource, params) => {
    if (Array.isArray(params?.id)) {
        return await getMany(resource, { ids: params.id })
    }
    const queryData = getOneQuery(resource, params);
    const variables = { id: params.id };
    const data = await fetchWithToken('POST', queryData.query, variables);
    return { data: data[queryData.queryName] };
}

const getList = async (resource, params) => {
    const { sort } = params || {}
    const currentPageNumber = parseInt(params?.pagination?.page) || 1
    if (!paginationData[resource]) {
        paginationData[resource] = {}
    }
    let queryData = null
    if (typeof (params?.filter ?? 0) === 'object' && Object.keys(params.filter)[0]) {
        const filterQueryName = Object.keys(params.filter)[0]
        const filterQuery = queries[filterQueryName]

        if (filterQuery) {
            queryData = {
                queryName: filterQueryName,
                query: filterQuery
            }
            params = {
                ...params.filter[filterQueryName],
                pagination: params.pagination
            }
        } else if (filterQueryName === 'q') {
            params.filter = {
                name: {
                    contains: params.filter[filterQueryName]
                }
            }
        }
    }
    if (!queryData) {
        queryData = getListQuery(resource, params);
    }

    const filter = params?.filter && Object.keys(params.filter).length && { filter: params?.filter }
    const { page, perPage } = params?.pagination || {}
    const nextTokenInput = paginationData[resource][currentPageNumber]?.startingToken
    const variables = {
        limit: perPage,
        nextToken: (!nextTokenInput || isNaN(nextTokenInput) ? (page - 1) * perPage : nextTokenInput) || null,
        ...(filter || {}),
        sort
    }

    const data = await fetchWithToken('POST', queryData.query, variables);
    const { items, total, nextToken } = data?.[queryData?.queryName] || {}
    const nextTokenValue = nextToken && isNaN(JSON.parse(nextToken)) ? nextToken : null

    paginationData[resource][currentPageNumber] = { startingToken: paginationData[resource][currentPageNumber - 1]?.nextTokenValue, nextTokenValue }
    paginationData[resource][currentPageNumber + 1] = { startingToken: nextTokenValue }
    return { data: items ?? [], nextToken: nextTokenValue, total: total ?? (nextTokenValue ? (currentPageNumber * (params?.pagination?.perPage || 1)) + 1 : (((currentPageNumber || 1) - 1) * (params?.pagination?.perPage || 1)) + (items || []).length) };
}

const getMany = async (resource, params) => {
    const promiseResults = await Promise.all(params.ids.map(async (id) => getOne(resource, { id })));
    const items = promiseResults.map(item => item.data).filter(item => item)
    return { data: items, total: items.length };
}

const create = async (resource, params) => {
    const mutationData = getMutation(resource, MUTATIONS.CREATE);
    const variables = { input: params.data };
    const data = await fetchWithToken('POST', mutationData.mutation, variables);
    return { data: data[mutationData.mutationName] };
}

const update = async (resource, params) => {
    const mutationData = getMutation(resource, MUTATIONS.UPDATE);
    const variables = { input: { ...params.data, id: params.id } };
    cleanVariablesInput(variables.input);
    const data = await fetchWithToken('POST', mutationData.mutation, variables);
    return { data: data[mutationData.mutationName] };
}

const deleteOne = async (resource, params) => {
    const mutationData = getMutation(resource, MUTATIONS.DELETE);
    const variables = { input: { ...params.data, id: params.id } };
    cleanVariablesInput(variables.input);
    const data = await fetchWithToken('POST', mutationData.mutation, variables);
    return { data: data[mutationData.mutationName] };
}

const saveState = () => sessionStorage.setItem('paginationData', JSON.stringify(paginationData, null, 0))

const BQDataProvider = {
    getList,
    getOne,
    create,
    update,
    getMany,
    delete: deleteOne,
    deleteMany: () => { },
    saveState
};

export default BQDataProvider;
