/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    Create,
    Edit,
    SimpleForm,
    ReferenceInput,
    ReferenceField,
} from 'react-admin';
import { getDuplicates, validate, validateDuplicates, validateMAC, validateQuickConnectNumber, validateString } from '../utils/validations';

import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { OUSelectInput, setOU } from './organizationUnitSelector.component';
import { bqAuthData } from '../utils/bq-auth';
import { DeviceSize } from '../utils/bq_enums';
import { BQDropDown, BQInput } from './Generic/bq-input';
import { prepareFormData } from '../utils/transforms';

export const DeviceList = (props) => {
    const { isSuperAdmin, subjectOrPatient } = bqAuthData;
    return (
        <BQModelList {...props} disableDelete={true} nameField="serialNumber" showDates={true}>
            <TextField label="Serial number - main device" source="serialNumber" />
            {isSuperAdmin && <TextField label="Serial number - Bluetooth label" source="quickConnectNumber" />}
            {isSuperAdmin && <TextField source="macAddress" label="MAC address" />}
            <ReferenceField
                link={false}
                label={subjectOrPatient}
                source="patientId"
                reference="patients">
                <TextField source="idNumber" />
            </ReferenceField>
            {isSuperAdmin && <ReferenceField
                link={false}
                label="Clinic"
                source="organizationalUnitId"
                reference="organizationalUnits">
                <TextField source="name" />
            </ReferenceField>}
        </BQModelList>
    )
}

const deviceTransform = async (data, setDuplicates) => {
    const duplicates = await getDuplicates('Device', data, ['serialNumber', 'quickConnectNumber', 'macAddress']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    setOU(data)
    data.quickConnectNumber = data.quickConnectNumber?.toUpperCase();
    data.macAddress = data.macAddress && data.macAddress.toUpperCase();
    return prepareFormData(data)
}

const DeviceEditor = props => {
    const { record } = props
    const { isSuperAdmin, subjectOrPatient } = bqAuthData;
    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'A device',
        duplicates: props.duplicates
    }
    return (
        <SimpleForm {...props} autoComplete="off" warnWhenUnsavedChanges toolbar={<BQToolbar type="Device" nameField="serialNumber" />}>
            <BQSection title="Device Info">
                <BQInput source="serialNumber" label="Serial number - main device" validate={validateString} readOnly={!!record.serialNumber}
                    duplicates={duplicatesCheckData}
                />
                {isSuperAdmin && <BQInput source="quickConnectNumber" label="Serial number - Bluetooth label" validate={validateQuickConnectNumber} readOnly={!!record.quickConnectNumber} maxLength={4}
                    duplicates={duplicatesCheckData}
                />}
                {isSuperAdmin && <BQInput source="macAddress" label="MAC address" validate={validateMAC} readOnly={!!record.macAddress}
                    duplicates={duplicatesCheckData}
                />}
                {isSuperAdmin && <BQInput source="resistance" validate={validate} readOnly={!!record.resistance} />}
                <BQDropDown source="deviceSize" choices={DeviceSize} unsorted validate={validate} readOnly={!!record.deviceSize} />
                <ReferenceInput
                    perPage={1000}
                    label={subjectOrPatient}
                    source="patientId"
                    reference="patients">
                    <BQDropDown source="patientId" optionText={item => item.idNumber} />
                </ReferenceInput>
                {isSuperAdmin && <OUSelectInput />}
            </BQSection>
        </SimpleForm>
    )
}

const DeviceMutation = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();

    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => deviceTransform(data, setDuplicates) }

    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <DeviceEditor redirect={bqCreateRedirection} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <DeviceEditor redirect={bqEditRedirection} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

export const DeviceCreate = (props) => DeviceMutation(true, props)

export const DeviceEdit = (props) => DeviceMutation(false, props)
