import { setOU } from '../components/organizationUnitSelector.component';

export const createTransform = ({ modules, ...data }) => {
    data.organizationalUnitId && setOU(data);
    data.deletedAt = 0;
    return data
};

export const editTransform = ({ modules, ...data }) => {
    data.organizationalUnitId && setOU(data);
    delete data.treatments
    delete data.treatmentProtocol
    return data
};

function removeField(obj, fieldToRemove) {
    for (let field in obj) {
        if (obj.hasOwnProperty(field)) {
            if (field === fieldToRemove) {
                delete obj[field];  // remove the field
            } else if (typeof obj[field] === 'object' && obj[field] !== null) {
                removeField(obj[field], fieldToRemove);  // recursive call for nested objects
            }
        }
    }
    return obj;
}

export const prepareFormData = (data) => {
    removeField(data, '__typename')
    data.deletedAt = data.deletedAt || 0
    Object.keys(data).filter(key => key.match(/_scan/)).forEach(key => delete data[key])
    return data
}