/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://r4b7ydzgdbggpixolh2k6seq7m.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:b562f8bd-903e-4596-b029-dc0ffcfd5b59",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_uxBXeqlpL",
    "aws_user_pools_web_client_id": "6t4d2n3ol49cb16gjav3hfnr9v",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "AuditTrail-AuthenticatedOperations-alzdevback",
            "region": "us-east-1"
        },
        {
            "tableName": "AuditTrail-EventNames-alzdevback",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsCategories-alzdevback",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsEventLog-alzdevback",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsExercises-alzdevback",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsPatients-alzdevback",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsTreatments-alzdevback",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAppEvents-alzdevback",
            "region": "us-east-1"
        },
        {
            "tableName": "MobileDeviceInfo-alzdevback",
            "region": "us-east-1"
        },
        {
            "tableName": "SharedData-alzdevback",
            "region": "us-east-1"
        },
        {
            "tableName": "SimMapping-alzdevback",
            "region": "us-east-1"
        },
        {
            "tableName": "TreatmentDefinitions-alzdevback",
            "region": "us-east-1"
        },
        {
            "tableName": "UniqueValues-alzdevback",
            "region": "us-east-1"
        },
        {
            "tableName": "ZoomCredentials-alzdevback",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "credentials145940-alzdevback",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
