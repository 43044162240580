export const choicesRole = [
    { name: 'Super administrator', id: 'Super Administrator' },
    { name: 'Administrator', id: 'Administrator' },
    { name: 'Randomizer', id: 'Randomizator' },
    { name: 'Auditor', id: 'Auditor' },
    { name: 'Operator', id: 'Operator' },
    { name: 'Caregiver', id: 'Caregiver' },
    { name: 'Viewer', id: 'Viewer' }
];

export const choicesGender = [
    { name: 'Male', id: 'MALE' },
    { name: 'Female', id: 'FEMALE' }
];

export const choicesSetType = [
    { name: 'Repetitions', id: false },
    { name: 'Seconds', id: true }
];

export const choicesTreatmentStageType = [
    { name: 'During Treatment', id: 'DURING_TREATMENT' },
    { name: 'Post Treatment', id: 'POST_TREATMENT' }
];

export const equipmentIdInApp = [
    { name: 'thera_tubing', id: 'thera_tubing' },
    { name: 'dumbbells', id: 'dumbbells' },
    { name: 'cuff_weights', id: 'cuff_weights' },
    { name: 'squeeze_ball', id: 'squeeze_ball' },
    { name: 'keyboard', id: 'keyboard' },
    { name: 'calculator', id: 'calculator' },
    { name: 'clay', id: 'clay' },
    { name: 'piano', id: 'piano' },
    { name: 'pipe_cleaners', id: 'pipe_cleaners' },
    { name: 'buttons', id: 'buttons' },
    { name: 'bubble_wrap', id: 'bubble_wrap' },
    { name: 'kinetic_sand', id: 'kinetic_sand' },
    { name: 'zippers', id: 'zippers' },
    { name: 'blank_paper', id: 'blank_paper' },
    { name: 'pen', id: 'pen' },
    { name: 'laundry_clips', id: 'laundry_clips' },
    { name: 'cloth', id: 'cloth' },
    { name: 'weighted_dice', id: 'weighted_dice' },
    { name: 'container', id: 'container' },
    { name: 'pegs', id: 'pegs' },
    { name: 'beads', id: 'beads' },
    { name: 'nuts', id: 'nuts' },
    { name: 'padlock', id: 'padlock' },
    { name: 'theraputty', id: 'theraputty' },
    { name: 'extension_cord', id: 'extension_cord' },
    { name: 'scissors', id: 'scissors' },
    { name: 'paper', id: 'paper' },
    { name: 'lego', id: 'lego' },
    { name: 'jar', id: 'jar' },
    { name: 'cone', id: 'cone' },
    { name: 'measuring_cup', id: 'measuring_cup' },
    { name: 'blocks', id: 'blocks' },
    { name: 'cans', id: 'cans' },
    { name: 'ball', id: 'ball' }
]

export const exerciseInApp = [
    { name: 'seated_marches', id: 'seated_marches' },
    { name: 'seated_knee_extension', id: 'seated_knee_extension' },
    { name: 'seated_knee_flexion', id: 'seated_knee_flexion' },
    { name: 'ankle_dorsiflexion_with_eversion', id: 'ankle_dorsiflexion_with_eversion' },
    { name: 'ankle_plantar_flexion', id: 'ankle_plantar_flexion' },
    { name: 'hip_abduction', id: 'hip_abduction' },
    { name: 'sit_to_stand', id: 'sit_to_stand' },
    { name: 'standing_balance', id: 'standing_balance' },
    { name: 'otago_knee_bends', id: 'otago_knee_bends' },
    { name: 'step_ups_on_aerobic_stepper', id: 'step_ups_on_aerobic_stepper' },
    { name: 'towel_step_overs', id: 'towel_step_overs' },
    { name: 'press_down', id: 'press_down' },
    { name: 'shoulder_external_rotation', id: 'shoulder_external_rotation' },
    { name: 'shoulder_flexion_arm_to_the_front', id: 'shoulder_flexion_arm_to_the_front' },
    { name: 'shoulder_abduction_arm_to_the_side', id: 'shoulder_abduction_arm_to_the_side' },
    { name: 'wrist_exercise_part_1', id: 'wrist_exercise_part_1' },
    { name: 'wrist_exercise_part_2', id: 'wrist_exercise_part_2' },
    { name: 'wrist_exercise_part_3', id: 'wrist_exercise_part_3' },
    { name: 'elbow_flexion', id: 'elbow_flexion' },
    { name: 'elbow_extension', id: 'elbow_extension' },
    { name: 'grip_power', id: 'grip_power' },
    { name: 'open_fingers_part_1', id: 'open_fingers_part_1' },
    { name: 'open_fingers_part_2', id: 'open_fingers_part_2' },
    { name: 'the_twist', id: 'the_twist' },
    { name: 'cutting', id: 'cutting' },
    { name: 'waiter', id: 'waiter' },
    { name: 'pouring', id: 'pouring' },
    { name: 'start_the_ball_rolling', id: 'start_the_ball_rolling' },
    { name: 'drop_and_catch', id: 'drop_and_catch' },
    { name: 'laundry', id: 'laundry' },
    { name: 'buttons', id: 'buttons' },
    { name: 'hanging_up_the_clothes', id: 'hanging_up_the_clothes' },
    { name: 'lego', id: 'lego' },
    { name: 'block_towers', id: 'block_towers' },
    { name: 'pick_up_sticks', id: 'pick_up_sticks' },
    { name: 'paper_clips', id: 'paper_clips' },
    { name: 'flip_over', id: 'flip_over' },
    { name: 'jars', id: 'jars' },
    { name: 'arm_stretch', id: 'arm_stretch' },
    { name: 'hand_and_wrist_stretch', id: 'hand_and_wrist_stretch' },
    { name: 'shoulder_shrug', id: 'shoulder_shrug' },
    { name: 'aeorbic_stepper_corner_taps', id: 'aeorbic_stepper_corner_taps' },
    { name: 'backwards_step', id: 'backwards_step' },
    { name: 'floor_taps', id: 'floor_taps' },
    { name: 'forward_lunge', id: 'forward_lunge' },
    { name: 'lateral_step', id: 'lateral_step' },
    { name: 'lateral_step_ups', id: 'lateral_step_ups' },
    { name: 'modified_single_leg_stance', id: 'modified_single_leg_stance' },
    { name: 'squat_taps', id: 'squat_taps' },
    { name: 'standing_calf_raise', id: 'standing_calf_raise' },
    { name: 'standing_hip_abduction', id: 'standing_hip_abduction' },
    { name: 'standing_hip_extension', id: 'standing_hip_extension' },
    { name: 'standing_knee_flexion', id: 'standing_knee_flexion' },
    { name: 'standing_march_with_opposition', id: 'standing_march_with_opposition' },
    { name: 'step_downs', id: 'step_downs' },
    { name: 'step_ups_part_2', id: 'step_ups_part_2' },
    { name: 'step_ups_part_3', id: 'step_ups_part_3' },
    { name: 'walking_and_turning_towards_affected_side', id: 'walking_and_turning_towards_affected_side' },
]

export const Residence = [
    { name: 'Clinic', id: 'CLINIC' },
    { name: 'Home', id: 'HOME' }
]


export const Relation = [
    { name: 'Partner', id: 'PARTNET' },
    { name: 'Son', id: 'SON' },
    { name: 'Daughter', id: 'DAUGHTER' },
    { name: 'Sibling', id: 'SIBLING' },
    { name: 'Professional', id: 'PROFESSIONAL' },
    { name: 'Other', id: 'OTHER' },
    { name: 'N/A', id: 'NA' }
]

export const DeviceSize = [
    { name: 'Small', id: 'SMALL' },
    { name: 'Medium', id: 'MEDIUM' },
    { name: 'Large', id: 'LARGE' }
]

export const ControlPatientOptions = [
    { name: 'Unset', id: 'UNSET' },
    { name: 'Sham', id: 'SHAM' },
    { name: 'Treatment', id: 'TREATMENT' }
]

export const TreatmentTypes = [
    { name: 'Right', id: 'RIGHT' },
    { name: 'Left', id: 'LEFT' }
]

export const ChangesToDevice = [
    { name: 'None', id: 'None' },
    { name: 'Added 1 waistband extension', id: 'Added 1 waistband extension' },
    { name: 'Added 2 waistband extensions', id: 'Added 2 waistband extensions' },
    { name: 'Added shoulder strap extension', id: 'Added shoulder strap extension' },
    { name: 'Provided cushion', id: 'Provided cushion' },
    { name: 'Other - Please specify', id: 'Other - Please specify' }
]

export const QuestionnaireItemTypes = [
    { name: 'Number', id: 'NUMBER' },
    { name: 'Free text', id: 'TEXT' },
    { name: 'Boolean', id: 'BOOLEAN' },
    { name: 'Boolean (why yes)', id: 'BOOLEAN_REASON_YES' },
    { name: 'Boolean (why no)', id: 'BOOLEAN_REASON_NO' },
    { name: 'Text options', id: 'OPTIONS_TEXT' },
    { name: 'Numeric options', id: 'OPTIONS_NUMBERS' },
    { name: 'A note', id: 'NOTE' },
]

export const SessionsCounterTypes = [
    { name: 'Total sessions', id: 'TOTAL_SESSIONS' },
    { name: 'Weekly sessions', id: 'WEEKLY_SESSIONS' },
]