import dayjs from "dayjs";

export const limitText = (text, maxLength) => {
    if (!text) {
        return ''
    }
    maxLength = maxLength || 64;
    return text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text
}

export const getDateTime = (date, hideTime) => {
    const dateObject = new Date(date);
    if (isNaN(dateObject.getTime())) {
        return ''
    }
    return dayjs(dateObject).format(`DD/MM/YYYY${hideTime ? '' : ' hh:mm A'}`)
}

export const replacePlaceholders = (text, formData, value) => {
    const matches = text && text.matchAll(/\[(.*?)\]/g);
    if (matches) {
        for (const match of matches) {
            const formValue = formData[`${match[1]}`]
            text = text.replace(match[0], match[1] === 'value' ? value : formValue)
        }
    }
    return text;
}

export const getTranslatedName = (item, langCode) => item.nameTranslations?.find(lang => lang.code === langCode)?.text || item.name || null

export const parseTime = (time, removeHours) => {
    if (!time) {
        return null
    }
    const hours = parseInt(time / 3600)
    const minutes = parseInt((time % 3600) / 60)
    const seconds = time % 60
    return `${!removeHours && `${timePadStart(hours)}:` || ''}${timePadStart(minutes)}:${timePadStart(seconds)}`
}

export const timePadStart = (value) => {
    return value.toString().padStart(2, '0')
}

export const fixValue = (value, pow) => {
    const muliplyer = Math.pow(10, pow)
    return (parseInt(value * muliplyer) / muliplyer)
}
export const decapitalize = (string) => string && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() || ''

export const lineBreaks = (text, defaultValue) => ((text || defaultValue)?.replace(/(?:\r\n|\r|\n)/g, '<br/>') || '').split('<br/>').map(item => <span>{item}<br /></span>)

export const getLabel = (child, language) => {
    const { source, label } = child.props || {};
    if (label) {
        return label;
    }
    const toReturn = (source?.length ? source.slice(0, 1).toUpperCase() + source.slice(1) : source)
        ?.split(/(?=[A-Z])/)?.join(' ')
    if (child.type?.name === 'TranslationInput') {
        return toReturn + (language?.code?.toLowerCase() === 'en' ? '' : ` (${language?.name})`);
    }
    return toReturn
}

export const getValueByPath = (obj, path) => path
    ?.replace(/\[(\w+)\]/g, '.$1')
    ?.split('.')
    ?.filter(t => t.length > 0)
    ?.reduce((prev, curr) => prev ? prev[curr] : undefined, obj);