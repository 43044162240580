/* eslint-disable react/display-name */
import { Amplify } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Admin, Resource, SimpleForm } from 'react-admin';
import awsExports from './aws-exports';
import PatientComponent from './components/patient.component';
import TreatmentProtocolComponent from './components/treatmentProtocol.component';
import CognitoUserComponent from './components/cognitoUser.components';
import { OrganizationCreate, OrganizationEdit, OrganizationList } from './components/organization.component';
import ExercisesCategoryComponent from './components/exercisesCategory.component';
import ExerciseComponent from './components/exercises.component';
import TreatmentStageComponent from './components/treatmentStage.component';
import EquipmentComponent from './components/equipment.component';
import ProgressionComponent from './components/progression.component';
import { AuditTrailList } from './components/auditTrail.component'
import { bq_theme, useBQStyles } from './themes';
import { Auth } from 'aws-amplify';
import { bqAuthData, clearBQAuthData, setBQAuthData } from './utils/bq-auth';
import { DeviceCreate, DeviceEdit, DeviceList } from './components/device.component';

import langSelected from './redux/reducers/languageReducer'
import tempPasswordObject from './redux/reducers/tempPasswordReducer'
import { LanguageSelection } from './components/Generic/bq-form-components';
import { LanguageCreate, LanguageEdit, LanguageList } from './components/language.component';
import { endVideoMeeting } from './utils/bq-zoom';
import AnalyticsComponent from './components/analytics.component';
import QuestionnaireComponent from './components/questionnaire.component';
import BQDataProvider from './BQDataProvider';

import { useAvailableResources } from './components/Generic/hooks'
import TempPasswordDialog from './components/Generic/TempPasswordDialog';
import TextMessageComponent from './components/textMessage.component';

const queries = {
    ...require('./graphql/queries'),
    ...require('./extensions/queries'),
}

const mutations = {
    ...require('./graphql/mutations'),
}

Amplify.configure(awsExports);


const ResourceRouter = () => {
    useAvailableResources()
    return <></>
}

const App = (props) => {
    const [userName, setUserName] = useState();
    useEffect(async () => {
        const autheticatedUser = await Auth.currentAuthenticatedUser()
        setBQAuthData(autheticatedUser)
        setUserName(bqAuthData.name);
    }, [])

    const { checkAuthState } = props;
    const bqClasses = useBQStyles();

    const {
        userAllowed,
        isSuperAdmin,
        isAdmin,
        isOperator,
        isAuditor,
        isViewer
    } = bqAuthData

    return !bqAuthData.role ?
        <>Role is not set for current user</>
        :
        <Admin
            dataProvider={BQDataProvider}
            authData={bqAuthData}
            theme={bq_theme}
            customReducers={{ langSelected, tempPasswordObject }}
            logoutButton={null}
            refreshButton={null}
            operations={{ queries, mutations }}
            ready={null}
            options={{
                enableAdminQueries: false
            }}>

            <TempPasswordDialog />

            <ResourceRouter />

            <Resource
                name="CognitoUsers"
                list={isAdmin && CognitoUserComponent}
                edit={isAdmin && CognitoUserComponent}
                create={isAdmin && CognitoUserComponent}
                options={{ label: 'Users' }}
            />

            <Resource
                name="languages"
                list={isSuperAdmin && LanguageList}
                create={isSuperAdmin && LanguageCreate}
                edit={isSuperAdmin && LanguageEdit}
            />

            <Resource
                name="patients"
                list={(isSuperAdmin || !isAuditor) && userAllowed && PatientComponent}
                create={(isSuperAdmin || !isAuditor) && userAllowed && PatientComponent}
                edit={(isSuperAdmin || !isAuditor) && userAllowed && PatientComponent}
                options={{ label: `${bqAuthData.isMarketTargetAudience ? 'Patients' : 'Subjects'}`, visible: false }}
            />

            <Resource
                name="organizationalUnits"
                list={isSuperAdmin && OrganizationList}
                create={isSuperAdmin && OrganizationCreate}
                edit={isSuperAdmin && OrganizationEdit}
                options={{ label: 'Clinics' }}
            />

            <Resource
                name="devices"
                list={isSuperAdmin && DeviceList}
                create={isSuperAdmin && DeviceCreate}
                edit={isSuperAdmin && DeviceEdit}
            />

            <Resource name="progressions"
                list={isSuperAdmin && ProgressionComponent}
                create={isSuperAdmin && ProgressionComponent}
                edit={isSuperAdmin && ProgressionComponent}
                options={{ label: 'Progression' }}
            />

            <Resource name="equipments"
                list={isSuperAdmin && EquipmentComponent}
                create={isSuperAdmin && EquipmentComponent}
                edit={isSuperAdmin && EquipmentComponent}
                options={{ label: 'Equipment' }}
            />

            <Resource name="exercises"
                list={isSuperAdmin && ExerciseComponent}
                create={isSuperAdmin && ExerciseComponent}
                edit={isSuperAdmin && ExerciseComponent}
                options={{ label: 'Exercises' }}
            />

            <Resource name="exercisesCategorys"
                list={isSuperAdmin && ExercisesCategoryComponent}
                create={isSuperAdmin && ExercisesCategoryComponent}
                edit={isSuperAdmin && ExercisesCategoryComponent}
                options={{ label: 'Exercise Categories' }}
            />

            <Resource name="treatmentStages"
                list={isSuperAdmin && TreatmentStageComponent}
                create={isSuperAdmin && TreatmentStageComponent}
                edit={isSuperAdmin && TreatmentStageComponent}
                options={{ label: 'Exercise Stages' }}
            />

            <Resource
                name="treatmentProtocols"
                list={isSuperAdmin && TreatmentProtocolComponent}
                create={isSuperAdmin && TreatmentProtocolComponent}
                edit={isSuperAdmin && TreatmentProtocolComponent}
                options={{ label: 'Treatment Protocols' }}
            />

            <Resource
                name="auditTrails"
                list={isAuditor && AuditTrailList}
                create={null}
                edit={null}
                options={{ label: 'Audit Trail' }}
            />

            <Resource
                name="auditTrailEventNames"
                list={null}
                create={null}
                edit={null}
                options={{ label: 'Audit Trail' }}
            />

            <Resource
                name="questionnaires"
                list={isSuperAdmin && QuestionnaireComponent}
                create={isSuperAdmin && QuestionnaireComponent}
                edit={isSuperAdmin && QuestionnaireComponent}
                options={{ label: 'Questionnaires' }}
            />

            <Resource
                name="patientAnalytics"
                list={(isAdmin || isOperator || isViewer) && AnalyticsComponent}
                create={null}
                edit={null}
                options={{ label: 'Dashboard' }}
            />

            <Resource
                name="textMessages"
                list={(isAdmin || isOperator) && TextMessageComponent}
                create={(isAdmin || isOperator) && TextMessageComponent}
                edit={(isAdmin || isOperator) && TextMessageComponent}
                options={{ label: 'Text Messages' }}
            />

            <Resource
                name="mobileDeviceUsers"
                list={null}
                create={null}
                edit={null}
                options={{ label: null }}
            />

            {!userAllowed && <div style={{ position: 'absolute', top: '50%', left: '50%', height: '64px', zIndex: '99999' }}>The Admin panel is not available for given user role</div> || <></>}

            <div style={{
                borderRadius: 5,
                backgroundColor: 'black',
                textColor: 'red !important',
                color: 'white',
                position: 'fixed',
                zIndex: 1300,
                width: 384,
                maxWidth: 192,
                bottom: 64,
                left: 10,
                verticalAlign: 'middle',
                alignItems: 'center',
                textAlign: 'left',
                display: isSuperAdmin ? 'block' : 'none'
            }}>
                <div style={{ marginLeft: 16, paddingTop: 16, marginBottom: -20 }}>Language</div>
                <SimpleForm toolbar={<></>}>
                    <LanguageSelection />
                </SimpleForm>

            </div>

            <div className={bqClasses.userInfoSection}>
                <div className={bqClasses.centerContent}>
                    {userName}
                </div>
            </div>
            <div id="logout_button" className={bqClasses.logoutButton} onClick={async () => {
                endVideoMeeting()
                await Auth.signOut()
                clearBQAuthData()
                checkAuthState();
            }}></div>
        </Admin>
}


export default App