import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleListItemExpand } from "react-admin";

export const useExpandOnlyOne = (resource, bqExport, atRootLevel) => {
    const expanded = useSelector(state => state.admin.resources[resource].list.expanded);
    const router = useSelector(state => state.router);
    const dispatch = useDispatch();

    const closeExpanded = (item) => {
        bqExport?.removeById(item)
        dispatch(toggleListItemExpand(resource, item))
    }

    useEffect(() => {
        if (!atRootLevel && router.location.pathname === `/${resource}`) {
            expanded.forEach(item => closeExpanded(item))
            return;
        };
        if (!expanded.length) {
            bqExport?.removeAllNested()
        }
        const nonNetedItems = expanded.filter(item => !item.match(/\(.*?\)/))
        const nonNestedItemId = nonNetedItems[0]
        if (nonNetedItems.length > 1) {
            const nestedItems = expanded.filter(item => item.match(/\((.*?)\)/i)?.[1] === nonNestedItemId)
            nestedItems.forEach(item => closeExpanded(item))
            closeExpanded(nonNestedItemId);
        } else if (nonNestedItemId) {
            const nestedItems = expanded.filter(item => item.match(/\((.*?)\)/i)?.[1] === nonNestedItemId)
            if (nestedItems.length > 1) {
                nestedItems.slice(0, nestedItems.length - 1)
                    .forEach(item => closeExpanded(item))
            }
        }
    }, [expanded, router]);
};

export const useAvailableResources = () => {
    const resources = useSelector(state => state.admin.resources);
    const router = useSelector(state => state.router);

    useEffect(() => {
        const currentResource = router.location.pathname.match(/\/(.*?)($|\/|\?)/)[1]
        const resourcesArray = Object.keys(resources).map(key => resources[key])
        if (!resourcesArray.length) {
            return
        }
        const availableResources = resourcesArray
            .filter(res =>
                Object.keys(res.props)
                    .filter(resKey => resKey.match('^has[A-Z]'))
                    .find(key => res.props[key] === true))
            .map(res => res.props.name)
        if (!availableResources.some(res => res === currentResource)) {
            location.href = `/#/${availableResources[0]}`
        }
    }, [resources, router]);
};