/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    Create,
    Edit,
    SimpleForm,
    AutocompleteArrayInput,
    DataProviderContext,
    LinearProgress,
    useNotify,
    useRedirect
} from 'react-admin';
import { validateEmailArray, validateString } from '../utils/validations';

import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { BQLabelWithInput, FunctionField } from './Generic/bq-form-components';
import { connect } from 'react-redux';
import { getDateTime, limitText } from '../utils/textUtils';
import { BQInput } from './Generic/bq-input';
import { useBQStyles } from '../themes';
import { getCachedMobileDeviceUsers } from '../utils/globals';

const recentRecepients = {}

class TextMessageComponent extends React.Component {
    render() {
        const basePath = this.props.basePath
        const pathname = this.props.location.pathname
        const isCreate = pathname === (`${basePath}/create`)
        const isEdit = !isCreate && pathname.indexOf(`${basePath}/`) === 0
        let ComponentView = null
        if (isCreate) {
            ComponentView = TextMessageCreate
        } else if (isEdit) {
            ComponentView = TextMessageEdit
        } else {
            ComponentView = TextMessageList
        }
        return (<ComponentView {...this.props} />)
    }
}

const mapStateToProps = state => {
    return { language: state.langSelected.language };
}

export default connect(mapStateToProps, {
})(TextMessageComponent);

const TextMessageList = (props) => {
    const classes = useBQStyles()
    return <BQModelList {...props}
        disableEdit
        disableDelete
        customButton={{
            title: 'Resend',
            className: classes.sendButton
        }}>
        <FunctionField label="Date" value={(item) => {
            recentRecepients[item.receiver] = Math.max(recentRecepients[item.receiver] || 0, item.timestamp)
            return getDateTime(item.timestamp)
        }} />
        <TextField source="receiver" />
        <FunctionField source="message" value={val => limitText(val, 128)} title={val => val.message} />
        <FunctionField source="status" value={val => val == 'true' ? 'Sent' : 'Failed'} />
    </BQModelList>
}

const textMessageTransform = async (data) => {
    return {
        id: data?.id,
        receivers: data?.receivers,
        message: data.message
    }
}

const TextMessageEditor = props => {
    if (props.id) {
        props.record.receivers = [props.record.receiver]
    }
    const [mobileDeviceUsers, setMobileDeviceUsers] = React.useState()
    const [messageLength, setMessageLength] = React.useState(props.record.message?.length || 0)
    const dataProvider = React.useContext(DataProviderContext);
    const notify = useNotify()
    const redirect = useRedirect()
    const maxMessageLength = 1000

    React.useEffect(async () => {
        let cachedMobileDeviceUsers = await getCachedMobileDeviceUsers(dataProvider)
        cachedMobileDeviceUsers
            .sort((a, b) => {
                const aRecent = recentRecepients[a.email] || 0
                const bRecent = recentRecepients[b.email] || 0
                let sortValue = bRecent - aRecent
                if (sortValue === 0) {
                    sortValue = a.email.localeCompare(b.email)
                }
                return sortValue
            })
        setMobileDeviceUsers(cachedMobileDeviceUsers)
    }, [])

    const { save, basePath } = props
    return <SimpleForm {...props}
        autoComplete="off"
        warnWhenUnsavedChanges
        toolbar={<BQToolbar type="Text Message" labelSubmit="Send" />}
        save={(data, redirectTo, _a) =>
            save(data, redirectTo, {
                ...(_a || {}),
                onSuccess: (data) => {
                    notify('Message was sent', { autoHideDuration: 6000, type: 'success' })
                    redirect(redirectTo, basePath, data.id, data);
                },
                onFailure: (error) => notify(
                    'Unfortunately the message was not sent. Please try again.',
                    { autoHideDuration: 6000, type: 'warning' }
                )
            })}
    >
        <BQSection title="Send Text Message" language={props.language}>
            <BQLabelWithInput idClassName={'receivers'}>
                Send To *
                {mobileDeviceUsers && <AutocompleteArrayInput label=""
                    source="receivers"
                    choices={mobileDeviceUsers}
                    optionText="email"
                    variant="outlined"
                    validate={validateEmailArray}
                /> || <LinearProgress />}
            </BQLabelWithInput>
            <BQInput
                label="Text message"
                source="message"
                validate={validateString}
                multiline
                maxLength={maxMessageLength}
                onChange={(e) => {
                    setMessageLength(e.target.value.length)
                }} />
            <BQLabelWithInput>
                <></>
                <span id="messageCharCounter">{`${messageLength} / ${maxMessageLength} characters`}</span>
            </BQLabelWithInput>
        </BQSection>
    </SimpleForm>
}

const TextMessageMutation = (isCreate, props) => {
    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => textMessageTransform(data) }
    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <TextMessageEditor {...props} redirect={bqCreateRedirection} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <TextMessageEditor {...props} redirect={bqEditRedirection} />
                </Edit>)
            }
        </div>
    )
}

const TextMessageCreate = (props) => TextMessageMutation(true, props)

const TextMessageEdit = (props) => TextMessageMutation(false, props)
