import React from 'react';
import { connect, useDispatch } from 'react-redux';

import styles from '../auth/auth.module.css'
import DraggableDialog from "./ConfirmationDialog"

import { tempPasswordUnhide, tempPasswordCopied, tempPasswordClear } from '../../redux/dispatchers/tempPasswordActions';
import { useBQStyles } from '../../themes';

class TempPasswordDialog extends React.Component {
    render() {
        return <Dialog {...this.props} />
    }
}

const Dialog = (props) => {
    const classes = useBQStyles()
    const dispatch = useDispatch()
    const { tempPassword } = props
    return <DraggableDialog
        disableCancel={true}
        confirmText='Close'
        open={tempPassword?.password}
        title={`Temporary Password for ${tempPassword?.username}`}

        onConfirm={() => dispatch(tempPasswordClear())}
        content={
            <div>
                Temporary password has been sent to the user. You can view it here, but note that once you click “close” you will not be able to re-access it.
                <table>
                    <tr>
                        <td style={{ minWidth: '250px' }}>
                            <p>Temporary Password: {tempPassword?.hide ? '********' : tempPassword?.password}</p>
                        </td>
                        <td>
                            <div
                                className={tempPassword?.hide ? classes.hiddenEyeButton : classes.eyeButton}
                                onMouseDown={() => dispatch(tempPasswordUnhide(true))}
                                onMouseUp={() => dispatch(tempPasswordUnhide(false))}
                                onMouseLeave={() => dispatch(tempPasswordUnhide(false))}
                            ></div>
                        </td>
                        <td>
                            <div
                                className={classes.copyButton}
                                onClick={() => {
                                    navigator.clipboard.writeText(tempPassword.password)
                                    dispatch(tempPasswordCopied())
                                }}
                            >
                            </div>
                        </td>
                        <td
                            className={classes.bqInputLabel}
                            style={{ color: 'green', fontWeight: 'bold' }}
                        >
                            {!!tempPassword?.copied && 'Copied to clipboard'}
                        </td>
                    </tr>
                </table>
            </div>
        } />
}

const mapStateToProps = state => {
    return { tempPassword: state.tempPasswordObject.tempPassword }
}

export default connect(mapStateToProps, {
})(TempPasswordDialog);